import { Link } from "gatsby"
import * as React from "react"
import styled from "styled-components"
import { Colors } from "./Styles"


const LinkWrapper = styled.div`
&: hover {
    color: black;
    transition: 0.3s ease-out;
}
`

const PortfolioPreview = ({ name, location, image, mobile, link }) => {
    if (mobile) {
        return (
            <Link style={{ textDecoration: "none", color: Colors.slate, filter: "drop-shadow(5px 5px 5px #91a9b7)" }} to={link}>
                <LinkWrapper style={{ background: Colors.pink, margin: "20px", width: "400px", height: "375px" }}>
                    <div style={{ justifyContent: "center", display: "flex", height: "300px" }}>{image}</div>
                    <h2 style={{ justifyContent: "center", display: "flex", fontSize: "18px" }}>{name}</h2>
                    <p style={{ justifyContent: "center", display: "flex", marginTop: "-15px", fontSize: "14px" }}>{location}</p>
                </LinkWrapper>
            </Link>
        )
    }
    return (
        <Link style={{ textDecoration: "none", color: Colors.slate, filter: "drop-shadow(5px 5px 5px #91a9b7)" }} to={link}>
            <LinkWrapper style={{ background: Colors.pink, margin: "20px", width: "400px", height: "400px" }}>
                <div style={{ justifyContent: "center", display: "flex", height: "300px" }}>{image}</div>
                <h2 style={{ justifyContent: "center", display: "flex" }}>{name}</h2>
                <p style={{ justifyContent: "center", display: "flex", marginTop: "-15px" }}>{location}</p>
            </LinkWrapper>
        </Link>
    )
}

export default PortfolioPreview