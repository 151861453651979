export const PortfolioStrings = {
    title: "Portfolio",
    description: "Check out our past events!",
    first: {
        name: "Esters' Baby Shower",
        location: "Virginia",
        link: "/Portfolio/Esters-Baby-Shower"
    },
    second: {
        name: "Sophie's First Birthday",
        location: "Colorado",
        link: "/Portfolio/Sophie-Birthday"
    },
    third: {
        name: "Baseballs or Bows Gender Reveal",
        location: "Colorado",
        link: "/Portfolio/Baseballs-or-Bows"
    },
    fourth: {
        name: "Pink Baby Shower",
        location: "Colorado",
        link: "/Portfolio/Pink-Baby-Shower"
    },
    fifth: {
        name: "Oh Boy! Baby Shower",
        location: "Colorado",
        link: "/Portfolio/Oh-Boy-Baby-Shower"
    },
    sixth: {
        name: "She's Two Wild Birthday",
        location: "Colorado",
        link: "/Portfolio/Shes-Two-Wild"
    },
    seventh: {
        name: "Hocus Pocus 2 Watch Party",
        location: "Colorado",
        link: "/Portfolio/Hocus-Pocus"
    },
    eighth: {
        name: "Cheyenne & Josiah Proposal",
        location: "Colorado",
        link: "/Portfolio/Cheyenne-Josiah"
    },
    ninth: {
        name: "LDS Church Christmas Party",
        location: "Colorado",
        link: "/Portfolio/Christmas"
    },
    tenth: {
        name: "Sip & See",
        location: "Westminster, Colorado",
        link: "/Portfolio/Sip-And-See"
    },
    eleventh: {
        name: "Tea Party Bridal Shower",
        location: "Boulder, Colorado",
        link: "/Portfolio/Tea-Party-Bridal"
    },
    twelth: {
        name: "Hole In One",
        location: "Firestone, Colorado",
        link: "/Portfolio/Hole-In-One"
    },
    thirteenth: {
        name: "Keime Wedding",
        location: "Louisville, Colorado",
        link: "/Portfolio/Keime-Wedding"
    }
}