import * as React from "react"
import { Colors, Padding } from "../components/Styles"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { useEffect, useState } from 'react'
import Seo from "../components/seo"
import PortfolioPreview from "../components/PortfolioPreview"
import { PortfolioStrings as Portfolio } from "../content/PortfolioText"
import { AnimationOnScroll } from 'react-animation-on-scroll';


const Text = styled.h1`
margin: 10px;
justify-content: center;
display: flex;
font-weight: normal;
`

const Texth2 = styled.h2`
margin: 10px;
justify-content: center;
display: flex;
font-weight: normal;
`

const MobileH1 = styled.h1`
font-size: 50px;
padding-top: 10px;
`

const MobileH2 = styled.h2`
font-size: 18px;
`

const portfolioPics = {
  first: <StaticImage objectFit="fill" src="../images/Portfolio/esters-baby-shower.png" />,
  second: <StaticImage objectFit="fill" src="../images/Portfolio/1st-birthday.png" />,
  third: <StaticImage objectFit="fill" src="../images/Portfolio/baseballs-bows.jpg" />,
  fourth: <StaticImage objectFit="fill" src="../images/Portfolio/pink-birthday.png" />,
  fifth: <StaticImage objectFit="fill" src="../images/Portfolio/oh-boy.png" />,
  sixth: <StaticImage objectFit="fill" src="../images/Portfolio/she-2-wild.png" />,
  seventh: <StaticImage objectFit="fill" src="../images/Portfolio/hocus-pocus.jpg" />,
  eighth: <StaticImage objectFit="fill" src="../images/Portfolio/cheyenne-josiah.jpg" />,
  ninth: <StaticImage objectFit="fill" src="../images/Portfolio/christmas.jpg" />,
  tenth: <StaticImage objectFit="fill" src="../images/Portfolio/sip-and-see.jpg" />,
  eleventh: <StaticImage objectFit="fill" src="../images/Portfolio/tea-party-bridal.jpg" />,
  twelth: <StaticImage objectFit="fill" src="../images/Portfolio/hole-in-one.jpg" />,
  thirteenth: <StaticImage objectFit="fill" src="../images/Portfolio/keime-wedding.jpg" />,
}

const PortfolioPage = () => {
  const [isDesktop, setDesktop] = useState(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 920)
    }
  }, [])

  const updateMedia = () => {
    setDesktop(window.innerWidth > 920)
  }

  useEffect(() => {
    window.addEventListener("resize", updateMedia)
    return () => window.removeEventListener("resize", updateMedia)
  })

  if (isDesktop) {
    return (
      <div>
        <div style={{ width: '100%', backgroundColor: Colors.purple, padding: Padding.banner, color: Colors.white }}>
          <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
            <Text>{Portfolio.title}</Text>
            <Texth2>{Portfolio.description}</Texth2>
          </AnimationOnScroll>
        </div>
        <div style={{ backgroundColor: Colors.cream, paddingLeft: Padding.gutter, paddingRight: Padding.gutter, minHeight: "100vh" }}>
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            <PortfolioPreview link={Portfolio.first.link} name={Portfolio.first.name} location={Portfolio.first.location} image={portfolioPics.first} />
            <PortfolioPreview link={Portfolio.second.link} name={Portfolio.second.name} location={Portfolio.second.location} image={portfolioPics.second} />
            <PortfolioPreview link={Portfolio.third.link} name={Portfolio.third.name} location={Portfolio.third.location} image={portfolioPics.third} />
            <PortfolioPreview link={Portfolio.fourth.link} name={Portfolio.fourth.name} location={Portfolio.fourth.location} image={portfolioPics.fourth} />
            <PortfolioPreview link={Portfolio.fifth.link} name={Portfolio.fifth.name} location={Portfolio.fifth.location} image={portfolioPics.fifth} />
            <PortfolioPreview link={Portfolio.sixth.link} name={Portfolio.sixth.name} location={Portfolio.sixth.location} image={portfolioPics.sixth} />
            <PortfolioPreview link={Portfolio.seventh.link} name={Portfolio.seventh.name} location={Portfolio.seventh.location} image={portfolioPics.seventh} />
            <PortfolioPreview link={Portfolio.eighth.link} name={Portfolio.eighth.name} location={Portfolio.eighth.location} image={portfolioPics.eighth} />
            <PortfolioPreview link={Portfolio.ninth.link} name={Portfolio.ninth.name} location={Portfolio.ninth.location} image={portfolioPics.ninth} />
            <PortfolioPreview link={Portfolio.tenth.link} name={Portfolio.tenth.name} location={Portfolio.tenth.location} image={portfolioPics.tenth} />
            <PortfolioPreview link={Portfolio.eleventh.link} name={Portfolio.eleventh.name} location={Portfolio.eleventh.location} image={portfolioPics.eleventh} />
            <PortfolioPreview link={Portfolio.twelth.link} name={Portfolio.twelth.name} location={Portfolio.twelth.location} image={portfolioPics.twelth} />
            <PortfolioPreview link={Portfolio.thirteenth.link} name={Portfolio.thirteenth.name} location={Portfolio.thirteenth.location} image={portfolioPics.thirteenth} />
          </div>
        </div >
      </div>
    )
  }
  return (
    <div style={{ paddingTop: "95px" }}>
      <div style={{ width: '100%', backgroundColor: Colors.purple, padding: Padding.mobileGutter, color: Colors.white }}>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
          <MobileH1 style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>{Portfolio.title}</MobileH1>
          <MobileH2 style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>{Portfolio.description}</MobileH2>
        </AnimationOnScroll>
      </div>
      <div style={{ backgroundColor: Colors.cream, paddingLeft: Padding.mobileGutter, paddingRight: Padding.gutter, minHeight: "100vh" }}>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", height: "100%" }}>
          <PortfolioPreview mobile link={Portfolio.first.link} name={Portfolio.first.name} location={Portfolio.first.location} image={portfolioPics.first} />
          <PortfolioPreview mobile link={Portfolio.second.link} name={Portfolio.second.name} location={Portfolio.second.location} image={portfolioPics.second} />
          <PortfolioPreview mobile link={Portfolio.third.link} name={Portfolio.third.name} location={Portfolio.third.location} image={portfolioPics.third} />
          <PortfolioPreview mobile link={Portfolio.fourth.link} name={Portfolio.fourth.name} location={Portfolio.fourth.location} image={portfolioPics.fourth} />
          <PortfolioPreview mobile link={Portfolio.fifth.link} name={Portfolio.fifth.name} location={Portfolio.fifth.location} image={portfolioPics.fifth} />
          <PortfolioPreview mobile link={Portfolio.sixth.link} name={Portfolio.sixth.name} location={Portfolio.sixth.location} image={portfolioPics.sixth} />
          <PortfolioPreview mobile link={Portfolio.seventh.link} name={Portfolio.seventh.name} location={Portfolio.seventh.location} image={portfolioPics.seventh} />
          <PortfolioPreview mobile link={Portfolio.eighth.link} name={Portfolio.eighth.name} location={Portfolio.eighth.location} image={portfolioPics.eighth} />
          <PortfolioPreview mobile link={Portfolio.ninth.link} name={Portfolio.ninth.name} location={Portfolio.ninth.location} image={portfolioPics.ninth} />
          <PortfolioPreview mobile link={Portfolio.tenth.link} name={Portfolio.tenth.name} location={Portfolio.tenth.location} image={portfolioPics.tenth} />
          <PortfolioPreview mobile link={Portfolio.eleventh.link} name={Portfolio.eleventh.name} location={Portfolio.eleventh.location} image={portfolioPics.eleventh} />
          <PortfolioPreview mobile link={Portfolio.twelth.link} name={Portfolio.twelth.name} location={Portfolio.twelth.location} image={portfolioPics.twelth} />
          <PortfolioPreview mobile link={Portfolio.thirteenth.link} name={Portfolio.thirteenth.name} location={Portfolio.thirteenth.location} image={portfolioPics.thirteenth} />
        </div>
      </div >
    </div>
  )

}

export const Head = () => <Seo title="Portfolio" />

export default PortfolioPage
